<template>
    <div>
        <page-header
            show-close
            @onClose="returnToRoot"
        />

        <div class="container text-center">
            <img
                src="@/assets/images/components/modal/balanceTransferConfirmation.svg"
                alt="Auto transfer requested"
            >

            <h5
                class="text-center fw-light my-1"
                v-html="$t('pages.autoBalanceTransfer.balanceTransferRequested.title')"
            />

            <div class="text-muted text-center mb-3">
                <span v-html="$t('pages.autoBalanceTransfer.balanceTransferRequested.message', { amount: formattedAmount, accountName: accountName })" />
            </div>

            <div class="d-grid">
                <button
                    class="btn btn-secondary mb-2"
                    type="submit"
                    @click="handleDoneWithBalanceTransferRequestedClick"
                >
                    {{ $t('pages.autoBalanceTransfer.balanceTransferRequested.cta') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '@/components/PageHeader'
    import { navigation } from '@/mixins/navigation'
    import format from '@/mixins/format'
    import { mapGetters } from 'vuex'

    export default {
        name: 'RecurringBalanceTransferRequested',
        components: {
            PageHeader,
        },
        mixins: [format, navigation],
        methods: {
            ...mapGetters(['mostRecentRecurringBalanceTransfer']),
            handleDoneWithBalanceTransferRequestedClick: function () {
                this.$logEvent('click_button_done_with_balance_transfer_requested', { transfer: this.recurringBalanceTransfer })
                this.returnToRoot()
            },
        },
        computed: {
            recurringBalanceTransfer() {
                return this.mostRecentRecurringBalanceTransfer()
            },
            formattedAmount() {
                return this.toFormattedUSD(this.recurringBalanceTransfer.amount)
            },
            accountName() {
                return this.recurringBalanceTransfer.accountName
            },
        },
        mounted: function () {
            this.$logEvent('view_recurring_balance_transfer_requested', { transfer: this.recurringBalanceTransfer })
        },
    }
</script>
